import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoMdDocument } from "react-icons/io";
import { IoAdd } from "react-icons/io5";
import { MdInsertPhoto } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IEntity, MLink } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  entity: IEntity | null;
  entity_photo: UploadFile[];
  entity_doc: UploadFile[];
  entity_link: string;
};

export default function LinkNew() {
  useTitle("New Link");

  const [entities, setEntities] = useState<IEntity[]>([]);
  const [info, setInfo] = useState<TInfo>({
    entity: null,
    entity_photo: [],
    entity_doc: [],
    entity_link: "",
  });

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const [updateRequest, updating] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onSubmit = (values: any) => {
    const _info = new FormData();
    _info.append("email", user?.email!);
    _info.append("entityId", `${info.entity?.id}`);
    _info.append("entity_link", info.entity_link);
    _info.append("entity_nick", values.entity_nick);
    _info.append("entity_link_comments", values.entity_link_comments);

    if (info.entity_photo.length > 0) {
      _info.append("entity_photo", info.entity_photo[0].originFileObj!);
    }

    if (info.entity_doc.length > 0) {
      _info.append("entity_doc", info.entity_doc[0].originFileObj!);
    }

    updateRequest("post", "providers/api/providerProfileUpdate", _info).then(
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "LINK",
            description: "Entity link is added and under review.",
          })
        );
      }
    );
  };

  useEffect(() => {
    listRequest("post", "entities/api/list/", {
      email: user?.email,
      pageRequested: 1,
    }).then((res) => {
      setEntities(res.data.entity_list);
    });
  }, [user?.email, listRequest]);

  if (loading) {
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <IoAdd className="mr-2" /> Entity Link
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onSubmit}>
          <Form.Item
            label="Entity"
            name="entity"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.entity)
                    return Promise.reject("Please select an entity.");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: entities.map((e) => ({
                  key: e.name,
                  label: e.name,
                  icon: (
                    <Avatar
                      src={e?.photo ? e.photo : "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, entity: e }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.entity ? (
                  <div className="flex items-center">
                    <Avatar
                      src={
                        info.entity?.photo
                          ? info.entity.photo
                          : "/images/covers/empty.png"
                      }
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.entity.name}</span>
                  </div>
                ) : (
                  "Select the Entity"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Link"
            name="entity_link"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.entity_link) {
                    return Promise.reject("Please input entity link!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MLink.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      entity_link: MLink.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.entity_link
                  ? MLink.getKey(info.entity_link)
                  : "Select the link"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Nickname"
            name="entity_nick"
            rules={[{ required: true, message: "Please input the nickname!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Comment" name="entity_link_comments">
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Photo" name="entity_photo">
            <Upload
              fileList={info.entity_photo}
              beforeUpload={() => false}
              onChange={({ fileList }) =>
                setInfo({
                  ...info,
                  entity_photo: fileList,
                })
              }
            >
              {info.entity_photo.length < 1 && (
                <Button className="text-xs" icon={<MdInsertPhoto />}>
                  Upload
                </Button>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label="Document" name="entity_doc">
            <Upload
              fileList={info.entity_doc}
              beforeUpload={() => false}
              onChange={({ fileList }) =>
                setInfo({
                  ...info,
                  entity_doc: fileList,
                })
              }
            >
              {info.entity_doc.length < 1 && (
                <Button className="text-xs" icon={<IoMdDocument />}>
                  Upload
                </Button>
              )}
            </Upload>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
