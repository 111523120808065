import { Button, Descriptions, Image, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { MdLocalOffer, MdMiscellaneousServices } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { useCTX } from "../../state";

export default function ServiceDetail() {
  useTitle("Service Detail");

  const [service, setService] = useState<any>(null);

  const {
    state: { user, settings, isocode },
  } = useCTX();
  const navigate = useNavigate();
  const params = useParams();

  const [request, loading] = useRequest(true);

  useEffect(() => {
    request("post", "services/api/serviceDetail/", {
      email: user?.email,
      serviceId: params.id,
    }).then((res) => {
      const s = res.data.service;
      setService({
        ...s,
        service_long_descr: s.long_descr,
        service_price: s.price,
      });
    });
  }, [params.id, request, user?.email]);

  useEffect(() => {
    if (!loading && !service) {
      navigate(-1);
    }
  }, [loading, service, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!service) {
    return null;
  }

  return (
    <div className="w-full font-poppins">
      <BackButton />
      <div className="flex items-center">
        <div className="flex flex-col w-1/4">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center pb-2"
          >
            <MdMiscellaneousServices className="mr-2" /> {service.service_name}
          </Typography.Title>
          {service.service_image ? (
            <Image
              src={service.service_image}
              className="rounded-md object-cover"
              width={300}
              height={200}
              preview={false}
            />
          ) : (
            <MdMiscellaneousServices className="text-9xl text-gray-300" />
          )}
        </div>
        <div className="w-3/4 ml-32 mt-20">
          <Descriptions layout="vertical">
            <Descriptions.Item label="Price">
              {isocode?.currency_symbol}. {service.service_price}
            </Descriptions.Item>
            <Descriptions.Item label="Duration">
              {service.exec_time}
            </Descriptions.Item>
            <Descriptions.Item label="Price Type">
              {service.price_type === "F"
                ? "Fix"
                : service.price_type === "V"
                ? "Variable"
                : "Mix"}
            </Descriptions.Item>
            <Descriptions.Item label="Category">
              {service.category_name}
            </Descriptions.Item>
            <Descriptions.Item label="Subcategory">
              {service.subcategory_name}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      <div className="flex justify-between my-8">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="text-gray-400 text-sm">Description</span>
            <span className="text-xs mt-2 whitespace-pre">
              {service.service_long_descr}
            </span>
          </div>
          <div className="flex flex-col mt-8">
            <span className="text-gray-400 text-sm">Terms & Conditions</span>
            <span className="text-xs mt-2 whitespace-pre">{service.tcs}</span>
          </div>
        </div>
        {settings.type === "provider" && (
          <Button
            type="primary"
            className="text-xs"
            icon={<MdLocalOffer size={10} />}
            onClick={() =>
              navigate("/provider/offerings/new", {
                state: {
                  service_id: service.service_id,
                },
              })
            }
          >
            Offer
          </Button>
        )}
      </div>
    </div>
  );
}
