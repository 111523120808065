import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { ICertificate, MDegreeLevel, MDurationUnit } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  degree_level: string;
  duration_unit: string;
  photo: UploadFile[];
};

export default function CertificateEdit() {
  useTitle("Edit Certificate");

  const [certificate, setCertificate] = useState<ICertificate>();
  const [info, setInfo] = useState<TInfo>({
    degree_level: "1",
    duration_unit: "1",
    photo: [],
  });

  const params = useParams();
  const { dispatch } = useCTX();
  const navigate = useNavigate();
  const { Preview, onPreview } = useImagePreview();

  const [updateRequest, updating] = useRequest();
  const [detailRequest, loading] = useRequest(true);

  const onSave = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("operation", "U");
    _info.append("certificate_id", `${certificate?.id}`);
    _info.append("title", values.title);
    _info.append("institution", values.institution);
    _info.append("degree_level", info.degree_level);
    _info.append("duration_unit", info.duration_unit);
    _info.append("duration_number", values.duration_number);
    _info.append("date_issued", values.date_issued.format("YYYY-MM-DD"));
    _info.append("descr", values.descr);

    if (info.photo[0].thumbUrl?.includes("data:")) {
      _info.append("photo", info.photo[0].originFileObj!);
    }

    updateRequest("post", "certificates/api/webaddupdate/", _info).then(() => {
      navigate(-1);
      dispatch(
        setAlert({
          type: "success",
          message: "CERTIFICATE",
          description: "Certificate added successfully!",
        })
      );
    });
  };

  useEffect(() => {
    detailRequest("post", "certificates/api/webdetail/", {
      token: localStorage.getItem("token"),
      cert_id: params.id,
    }).then((res) => {
      const c = res.data.certificate;
      setCertificate(c);
      setInfo({
        degree_level: c.degree_level,
        duration_unit: c.duration_unit,
        photo: c.photo
          ? [
              {
                name: c.photo.split("/").slice(-1)[0],
                uid: c.photo,
                url: c.photo,
                thumbUrl: c.photo,
              },
            ]
          : [],
      });
    });
  }, [params.id, detailRequest]);

  useEffect(() => {
    if (!loading && !certificate) {
      navigate(-1);
    }
  }, [loading, certificate, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!certificate) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <Preview />
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Certificate
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            title: certificate?.title,
            institution: certificate?.institution,
            date_issued: certificate?.date_issued
              ? dayjs(certificate.date_issued)
              : undefined,
            duration_number: certificate?.duration_number,
            descr: certificate?.descr,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Photo"
            name="photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) => setInfo({ ...info, photo: fileList })}
            >
              {info.photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Please input certificate title!" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Institution"
            name="institution"
            rules={[
              { required: true, message: "Please input the institution!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Level" name="degree_level" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MDegreeLevel.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      degree_level: MDegreeLevel.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.degree_level
                  ? MDegreeLevel.getKey(info.degree_level)
                  : "Select the level"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Duration Unit" name="duration_unit" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MDurationUnit.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      duration_unit: MDurationUnit.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.duration_unit
                  ? MDurationUnit.getKey(info.duration_unit)
                  : "Select the duration unit"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="duration_number"
            initialValue={0}
            required
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Issue Date" name="date_issued" required>
            <DatePicker />
          </Form.Item>
          <Form.Item label="Description" name="descr" required>
            <TextArea rows={8} />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
