import { Button, Descriptions, List, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CiLock } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useRequest } from "../../hooks/useRequest";
import { IAuthorizationEvent } from "../../lib/model";
import { useCTX } from "../../state";

export default function Event() {
  const [events, setEvents] = useState<IAuthorizationEvent[]>([]);

  const [approving, setApproving] = useState<number | null>(null);
  const [rejecting, setRejecting] = useState<number | null>(null);

  const {
    state: { user },
  } = useCTX();
  const [updateRequest] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onApprove = useCallback(
    (eventId: number, status: "A" | "R") => {
      status === "A" ? setApproving(eventId) : setRejecting(eventId);
      updateRequest("post", "events/api/updateEvent/", {
        email: user?.email,
        eventId,
        status,
      })
        .then(() => {
          setEvents((old) => old.filter((e) => e.id !== eventId));
        })
        .finally(() =>
          status === "A" ? setApproving(null) : setRejecting(null)
        );
    },
    [user?.email, updateRequest]
  );

  useEffect(() => {
    listRequest("post", "events/api/listEvents/", {
      email: user?.email,
      pageRequested: 1,
    }).then((res) => {
      const e = res.data.events;
      setEvents([...e.appointments, ...e.team_members, ...e.offerings]);
    });
  }, [user?.email, listRequest]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <List
      className="px-8"
      itemLayout="vertical"
      dataSource={events}
      renderItem={(item) => (
        <List.Item
          extra={
            <div className="flex items-start">
              <Button
                type="primary"
                icon={<TiTick />}
                className="mr-2"
                onClick={() => onApprove(item.id, "A")}
                loading={approving === item.id}
                disabled={rejecting === item.id}
              >
                Accept
              </Button>
              <Button
                danger
                type="primary"
                icon={<RxCross2 />}
                onClick={() => onApprove(item.id, "R")}
                loading={rejecting === item.id}
                disabled={approving === item.id}
              >
                Reject
              </Button>
            </div>
          }
        >
          <List.Item.Meta
            avatar={<CiLock />}
            title={
              item.event_type === "A"
                ? "Appointment"
                : item.event_type === "T"
                ? "Team Membership"
                : "Offering Acceptance"
            }
            description={
              <Descriptions>
                {Object.keys(
                  item.event_type === "A"
                    ? item.appointment
                    : item.event_type === "T"
                    ? item.entity_link
                    : item.offering
                ).map((k) => {
                  const o = (
                    item.event_type === "A"
                      ? item.appointment
                      : item.event_type === "T"
                      ? item.entity_link
                      : item.offering
                  ) as any;

                  return k === "address" ? (
                    <Descriptions.Item key={k} label={k}>
                      {o[k].name}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item key={k} label={k}>
                      {o[k]}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            }
          />
        </List.Item>
      )}
    />
  );
}
