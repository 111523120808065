import { Image, Typography } from "antd";
import { useTitle } from "../hooks/useTitle";
import { BiGitRepoForked } from "react-icons/bi";
import { useTranslation } from "react-i18next";

type CardProps = {
  title: string;
  description: string;
  alignment: "left" | "right";
};

function AboutCard({ title, description, alignment }: CardProps) {
  return (
    <div
      className={`flex items-center ${
        alignment === "left" ? "flex-col-reverse" : "flex-col"
      } sm:flex-row my-4`}
    >
      {alignment === "left" && (
        <Image
          src="/images/covers/about.png"
          alt="Image Cover"
          preview={false}
          height={300}
          width={300}
          className="w-1/6 rounded-xl"
        />
      )}
      <Typography
        className={`${
          alignment === "left" ? "sm:mx-4" : ""
        } w-full sm:w-5/6 mb-4`}
      >
        <Typography.Title level={3} className="font-poppins">
          {title}
        </Typography.Title>
        <span
          className="text-gray-700 font-poppins"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Typography>
      {alignment === "right" && (
        <Image
          src="/images/covers/about.png"
          alt="Image Cover"
          preview={false}
          height={300}
          width={300}
          className="w-1/6 rounded-xl sm:mx-4"
        />
      )}
    </div>
  );
}

export default function About() {
  useTitle("About");

  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex flex-col justify-center items-center mt-[20%] mb-[38%]">
        <span className="text-7xl font-bold text-gray-800">{t("about")}</span>
        <a href="#content" className="self-start sm:self-center animate-pulse">
          <BiGitRepoForked
            size={40}
            className="mt-4 rotate-180 text-gray-800"
          />
        </a>
      </div>
      <div id="content" className="pt-[2%]">
        <AboutCard
          title="About Us"
          description={`
            We, from PONTRES, aim to heat up the job market, as well as meet any
            urgent demand that our Customers may have. To accomplish this, we
            partner with a network of trained independent service providers
            ready to fulfill any and all service's requests quickly, safely and
            effectively.
        `}
          alignment="left"
        />
        <AboutCard
          title="Mission"
          description={`
            PONTRES's Mission is to connect the trained Professional with the
            Client who needs a fast, safe and effective service. For this, we
            have created a platform where Professionals can register their
            qualifications and certifications that enable them to provide high
            quality services in which they are fully qualified. At the same
            time, our objective is for the Client to have access to a wide range
            of Professionals from his region and to be able to choose the one
            that best suits his needs - whether by time to perform the service,
            qualifications or by price. The Client can also count on capable and
            certified Professionals, who have had their qualifications tested by
            a rigorous scrutiny, and can trust in the excellence and safety of
            the service to be provided.
      `}
          alignment="right"
        />
        <AboutCard
          title="How it works?"
          description={`
            The idea is very simple, you open the application, select the
            service you are in immediate need of; select the service´s address
            and payment method; and an instant search is performed to find
            providers currently available nearby. All the options will start to
            appear to you. Then, just choose an option, request the service and
            within 3 minutes you should receive confirmation that the provider
            is on its way. <br /> <br />
            24 hours a day, Yunee will be there to support you whenever you
            need it. <br />
            <b>
              YOU NEED &nbsp;=&nbsp; WE OFFER.
            </b>
      `}
          alignment="left"
        />
        <AboutCard
          title="Customer Service"
          description={`
      
            PONTRES provides a platform where the user can request, at any time
            and anywhere, services and be served quickly, efficiently and
            safely. Our list already has more than a hundred different types of
            services, fulfilled with capable and qualified professionals to
            provide the user with the best prices and professionals in their
            region. So you already know right? Next time you're at home, late at
            night and you have a plumber emergency, just call one of the many
            professionals in the region to remedy this adversity and countless
            others! Download the App!
      `}
          alignment="right"
        />
        <AboutCard
          title="Providers"
          description={`
      
            PONTRES provides a platform where the Professionals can enter their
            qualifications, skills and services in order to acquire extra
            income. There are numerous services that can be offered, both with
            services that require a capacity and technical proof, as for the
            most basic services, such as: Changing a Lamp, Washing dishes,
            Walking with Pets, among others. Enter our website and register!!
      `}
          alignment="left"
        />
      </div>
    </div>
  );
}
