import {
  Button,
  Descriptions,
  Image,
  Modal,
  Spin,
  Table,
  TableProps,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { MdOutlineSyncProblem } from "react-icons/md";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IDispute } from "../../../lib/model";
import { useCTX } from "../../../state";

export default function DisputeList() {
  useTitle("Dispute List");

  const [disputes, setDisputes] = useState<IDispute[]>([]);
  const [dispute, setDispute] = useState<IDispute>();

  const {
    state: { user },
  } = useCTX();

  const [request, loading] = useRequest(true);

  const columns: TableProps<IDispute>["columns"] = useMemo(
    () => [
      {
        key: "disputeId",
        title: "ID",
        dataIndex: "disputeId",
      },
      {
        key: "offer name",
        title: "Offering",
        dataIndex: "offer name",
      },
      {
        key: "service_date",
        title: "Service Date",
        dataIndex: "service_date",
        render: (_: any, d: any) => (
          <span>{new Date(d.service_date).toLocaleDateString()}</span>
        ),
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, d: any) => (
          <Button
            className="text-xs mr-2"
            icon={<IoMdEye />}
            onClick={() => setDispute(d)}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    request("post", "disputes/api/disputesList/", {
      email: user?.email,
      pageRequested: 1,
      isProvider: true,
    }).then((res) => {
      setDisputes(res.data.disp_list);
    });
  }, [user?.email, request]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!dispute}
        title={dispute?.disputeId}
        onCancel={() => setDispute(undefined)}
        footer={null}
      >
        <Descriptions layout="vertical" className="mt-8">
          <Descriptions.Item label="Title">{dispute?.title}</Descriptions.Item>
          <Descriptions.Item label="Description">
            {dispute?.description}
          </Descriptions.Item>
          <Descriptions.Item label="Offering">
            {dispute?.["offer name"]}
          </Descriptions.Item>
          <Descriptions.Item label="Customer">
            {dispute?.["customer name"]}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {dispute?.status}
          </Descriptions.Item>
          <Descriptions.Item label="Created">
            {new Date(dispute?.creation_date!).toLocaleDateString()}
          </Descriptions.Item>
        </Descriptions>
        <div className="flex justify-center mt-4 mb-8">
          {dispute?.photo && (
            <Image width={200} height={200} src={dispute.photo} />
          )}
        </div>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <MdOutlineSyncProblem className="mr-2" /> Disputes
          </Typography.Title>
        </div>
        <Table columns={columns} dataSource={disputes} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
