import {
  Button,
  Dropdown,
  Modal,
  Spin,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { GiRank3 } from "react-icons/gi";
import { MdAdd, MdDeleteOutline } from "react-icons/md";
import { TiBusinessCard } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { ILink, MLink } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function LinkList() {
  useTitle("Links List");

  const [links, setLinks] = useState<{ user: ILink[]; other: ILink[] }>({
    user: [],
    other: [],
  });
  const [deleteLink, setDeleteLink] = useState<ILink | null>(null);
  const [changeLink, setChangeLink] = useState<ILink | null>(null);

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const [deleteRequest, deleting] = useRequest();
  const [updateRequest, updating] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const userColumns: TableProps<ILink>["columns"] = useMemo(
    () => [
      {
        key: "entity_nick",
        title: "Nickname",
        dataIndex: "entity_nick",
      },
      {
        key: "entity_link",
        title: "Link",
        dataIndex: "entity_link",
        render: (l) => <span>{MLink.getKey(l)}</span>,
      },
      {
        key: "link_started",
        title: "Date",
        dataIndex: "link_started",
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        render: (s) => (
          <Tag color={s === "A" ? "green" : "yellow"}>
            {s === "A" ? "Active" : "Review"}
          </Tag>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, el: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              disabled={
                links.user[0].status === "R" ||
                (links.user[0].entity_link === "A" &&
                  !links.other.find((l) => l.entity_link === "A"))
              }
              onClick={() => setDeleteLink(el)}
            />
          </div>
        ),
      },
    ],
    [links]
  );

  const otherColumns: TableProps<ILink>["columns"] = useMemo(
    () => [
      {
        key: "provider",
        title: "Provider",
        dataIndex: "provider",
      },
      {
        key: "entity_nick",
        title: "Nickname",
        dataIndex: "entity_nick",
      },
      {
        key: "entity_link",
        title: "Link",
        dataIndex: "entity_link",
        render: (l) => <span>{MLink.getKey(l)}</span>,
      },
      {
        key: "link_started",
        title: "Date",
        dataIndex: "link_started",
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        render: (s) => (
          <Tag color={s === "A" ? "green" : "yellow"}>
            {s === "A" ? "Active" : "Review"}
          </Tag>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, el: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<GiRank3 />}
              disabled={el.status === "R"}
              onClick={() => setChangeLink(el)}
            />
          </div>
        ),
      },
    ],
    []
  );

  const onDelete = () => {
    deleteRequest("post", "providers/api/providerProfileUpdate", {
      email: user?.email,
      entityId: deleteLink?.entity_id,
      entityOp: "R",
    }).then(() => {
      setLinks((old) => ({ ...old, user: [] }));
      setDeleteLink(null);
      dispatch(
        setAlert({
          type: "success",
          message: "LINK",
          description: "Entity Link deleted successfully!",
        })
      );
    });
  };

  const onChange = () => {
    updateRequest("post", "entities/api/link/update/", {
      email: user?.email,
      linkId: changeLink?.id,
      entity_link: changeLink?.entity_link,
    }).then(() => {
      setLinks((old) => {
        const result = { ...old };
        const lidx = result.other.findIndex((l) => l.id === changeLink?.id);
        result.other = [...result.other];
        result.other[lidx] = {
          ...result.other[lidx],
          entity_link: changeLink!.entity_link,
        };
        return result;
      });
      setChangeLink(null);
      dispatch(
        setAlert({
          type: "success",
          message: "LINK",
          description: "Entity Link updated successfully!",
        })
      );
    });
  };

  useEffect(() => {
    listRequest("post", "entities/api/link/list/", {
      email: user?.email,
    }).then((res) => {
      const user_list = res.data.link_list.filter(
        (l: ILink) => l.provider === user?.email
      );
      const other_list = res.data.link_list.filter(
        (l: ILink) => l.provider !== user?.email
      );
      setLinks({ user: user_list, other: other_list });
    });
  }, [user?.email, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteLink}
        title="Delete Link"
        onCancel={() => setDeleteLink(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete link '{deleteLink?.entity_nick}'?
        </span>
      </Modal>
      <Modal
        open={!!changeLink}
        title={changeLink?.provider}
        onCancel={() => setChangeLink(null)}
        okText="Save"
        confirmLoading={updating}
        onOk={onChange}
      >
        <div className="my-8">
          <Dropdown
            trigger={["click"]}
            className="mb-2"
            menu={{
              items: MLink.keys.map((v) => ({
                key: v,
                label: v,
                onClick: () =>
                  setChangeLink({
                    ...changeLink!,
                    entity_link: MLink.getValue(v),
                  }),
              })),
            }}
          >
            <div className="text-xs text-gray-500 flex items-center cursor-pointer">
              {changeLink ? MLink.getKey(changeLink.entity_link) : null}
              <BiChevronDown className="ml-2" />
            </div>
          </Dropdown>
        </div>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <TiBusinessCard className="mr-2" /> Links
          </Typography.Title>
          {links.user.length === 0 && (
            <Button
              className="text-xs"
              icon={<MdAdd />}
              onClick={() => navigate("/provider/links/new")}
            >
              Add
            </Button>
          )}
        </div>
        <Table
          columns={userColumns}
          dataSource={links.user}
          pagination={false}
        />
        <ul className="my-4" style={{ fontSize: "0.7rem" }}>
          <li>To add a new entity link, delete the existing entity link.</li>
          <li>
            An entity must have an adminstrator, if not the admin cannot delete
            his link.
          </li>
        </ul>
        {links.other.length > 0 && (
          <div className="mt-16">
            <Typography.Title
              level={4}
              className="font-poppins flex items-center"
            >
              <TiBusinessCard className="mr-2" /> Members
            </Typography.Title>
            <Table
              columns={otherColumns}
              dataSource={links.other}
              pagination={false}
            />
          </div>
        )}
      </div>
    </PrivateRoute>
  );
}
