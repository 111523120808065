import { Tabs, TabsProps, Typography } from "antd";
import { useMemo, useState } from "react";
import { CiLock } from "react-icons/ci";
import { PrivateRoute } from "../../components/Route";
import Event from "./Event";
import Workflow from "./Workflow";
import { useCTX } from "../../state";

export default function Authorization() {
  const {
    state: { user },
  } = useCTX();

  const [activeTab, setActiveTab] = useState(
    user?.prov_status === "N" ? "workflows" : "events"
  );

  const tabs: TabsProps["items"] = useMemo(() => {
    const _ = [{ key: "workflows", label: "Workflows" }];

    if (user?.prov_status !== "N") {
      _.splice(0, 0, { key: "events", label: "Events" });
    }

    return _;
  }, [user?.prov_status]);

  return (
    <PrivateRoute>
      <div className="w-full">
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <CiLock className="mr-2" /> Authorizations
        </Typography.Title>
        <Tabs
          items={tabs}
          activeKey={activeTab}
          onTabClick={(k) => setActiveTab(k)}
          className="w-40 my-4 mx-8"
        />
        {activeTab === "events" ? <Event /> : <Workflow />}
      </div>
    </PrivateRoute>
  );
}
