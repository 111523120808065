import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./i18n/config";
import About from "./pages/About";
import BecomeProvider from "./pages/BecomeProvider";
import Calendar from "./pages/Calendar";
import Home from "./pages/Home";
import MainLayout from "./pages/Layout";
import Support from "./pages/Support";
import Session from "./pages/agent/Session";
import Workflow from "./pages/agent/Workflow";
import WorkflowDetail from "./pages/agent/WorkflowDetail";
import AuthLayout from "./pages/auth/Layout";
import AuthorizationDetail from "./pages/authorization/Detail";
import CustomerLayout from "./pages/customer/Layout";
import Settings from "./pages/customer/Settings";
import ProviderLayout from "./pages/provider/Layout";
import ServiceLayout from "./pages/service/Layout";
import { useCTX } from "./state";

function App() {
  const {
    state: { settings },
  } = useCTX();

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as any;
    if (settings.type === "provider") {
      link.href = "/images/favicons/provider.ico";
    } else {
      link.href = "/images/favicons/customer.ico";
    }
  }, [settings]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: settings.color,
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="service/*" element={<ServiceLayout />} />
            <Route path="about" element={<About />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="support" element={<Support />} />
            <Route path="authorization" element={<AuthorizationDetail />} />
            <Route path="become-provider" element={<BecomeProvider />} />
            <Route path="settings" element={<Settings />} />
            <Route path="session" element={<Session />} />
            <Route path="workflow" element={<Workflow />} />
            <Route path="workflow/:id" element={<WorkflowDetail />} />
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="customer/*" element={<CustomerLayout />} />
            <Route path="provider/*" element={<ProviderLayout />} />
          </Route>
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
