import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IPhone, MPhoneCountry, MPhoneType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  country: string;
  phone_type: string;
  primary: boolean;
};

export default function PhoneEdit() {
  useTitle("Edit Phone");

  const [phone, setPhone] = useState<IPhone>();
  const [info, setInfo] = useState<TInfo>({
    country: "",
    phone_type: "",
    primary: false,
  });

  const navigate = useNavigate();
  const { dispatch } = useCTX();
  const params = useParams();

  const [updateRequest, updating] = useRequest();
  const [detailRequest, loading] = useRequest(true);

  const onSave = (values: any) => {
    const _info = {
      ...values,
      ...info,
      country: info.country === "11" ? 1 : parseInt(info.country),
      token: localStorage.getItem("token"),
      phone_id: phone?.id,
      operation: "U",
    };

    updateRequest("post", "phones/api/webaddupdate/", _info).then(() => {
      navigate(-1);
      dispatch(
        setAlert({
          type: "success",
          message: "PHONE",
          description: "Phone updated successfully!",
        })
      );
    });
  };

  useEffect(() => {
    detailRequest("post", "phones/api/webdetail/", {
      token: localStorage.getItem("token"),
      phone_id: params.id,
    }).then((res) => {
      const p = res.data.phone;
      setPhone(p);
      setInfo({
        country: p.country,
        phone_type: p.phone_type,
        primary: p.primary,
      });
    });
  }, [params.id, detailRequest]);

  useEffect(() => {
    if (!loading && !phone) {
      navigate(-1);
    }
  }, [loading, phone, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!phone) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Phone
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            phone_name: phone?.phone_name,
            phone_number: phone?.phone_number,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Name"
            name="phone_name"
            rules={[{ required: true, message: "Please input phone name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item label="Country" name="country" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MPhoneCountry.keys.map((k) => ({
                  key: k,
                  label: k,
                  onClick: () =>
                    setInfo({
                      ...info,
                      country: MPhoneCountry.getValue(k),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.country
                  ? MPhoneCountry.getKey(`${info.country}`)
                  : "Select the country"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Number"
            name="phone_number"
            rules={[{ required: true, message: "Please input phone number!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Type" name="type" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MPhoneType.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      phone_type: MPhoneType.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.phone_type
                  ? MPhoneType.getKey(info.phone_type)
                  : "Select the type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Checkbox
            onChange={(e) => setInfo({ ...info, primary: e.target.checked })}
            checked={info.primary}
          >
            primary
          </Checkbox>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
