import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { caxios } from "../lib/constants";
import { useCTX } from "../state";
import { setAlert } from "../state/actionCreators";

export function useRequest(defaultLoading = false) {
  const [loading, setLoading] = useState(defaultLoading);

  const { dispatch } = useCTX();

  const request = useCallback(
    (
      method: "get" | "post",
      url: string,
      data?: any
    ): Promise<AxiosResponse> => {
      setLoading(true);

      const token = localStorage.getItem("token");
      return caxios[method](url, data, {
        ...(token ? { headers: { Authorization: `Token ${token}` } } : {}),
      })
        .catch((e) => {
          dispatch(
            setAlert({
              type: "error",
              message: "ERROR",
              description: e.request.responseText,
            })
          );
        })
        .finally(() => setLoading(false)) as any;
    },
    [dispatch]
  );

  return [request, loading] as const;
}
