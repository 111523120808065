import { Button, Form, Input, Typography } from "antd";
import { useCallback } from "react";
import { FiLogIn } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { PublicRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { useCTX } from "../../state";
import { setAlert, setUser } from "../../state/actionCreators";

type TInfo = {
  name: string;
  password: string;
};

export default function Login() {
  useTitle("Login");

  const {
    dispatch,
    state: { settings },
  } = useCTX();
  const navigate = useNavigate();
  const [request, loading] = useRequest();

  const onLogin = useCallback(
    (values: TInfo) => {
      request("post", "users/api/weblogin/", values).then((res) => {
        if (res.status === 200) {
          dispatch(setUser(res.data.user));
          localStorage.setItem("token", res.data.token);
        } else if (res.status === 201) {
          dispatch(
            setAlert({
              type: "success",
              message: "Verification",
              description:
                "A verification token has been emailed to you, please verify your account!",
            })
          );
          navigate("/auth/verify-account");
        }
      });
    },
    [dispatch, navigate, request]
  );

  return (
    <PublicRoute>
      <div className="w-full lg:w-1/2 mx-auto">
        <Typography.Title
          level={4}
          className="font-poppins flex items-center py-8"
        >
          <FiLogIn className="mr-2" /> Login
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onLogin}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please input a valid email!" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Typography.Text className="text-xs">
              New here?{" "}
              <Link to={"/auth/register"}>
                <span
                  className="underline font-bold"
                  style={{ color: settings.color }}
                >
                  register
                </span>
              </Link>
            </Typography.Text>
            <Button
              type="primary"
              className="text-xs ml-4"
              icon={<FiLogIn size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PublicRoute>
  );
}
