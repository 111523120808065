import ReactDOM from "react-dom/client";
import "react-h5-audio-player/lib/styles.css";
import "video-react/dist/video-react.css";
import App from "./App";
import "./index.css";
import { CTXProvider } from "./state";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CTXProvider>
    <App />
  </CTXProvider>
);
