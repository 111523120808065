import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { FaCheck, FaHome } from "react-icons/fa";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";

export default function EmailVerify() {
  useTitle("Verify Email");

  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const [request, loading] = useRequest(true);

  useEffect(() => {
    request("post", "users/api/webmailverify/", {
      token: localStorage.getItem("token"),
      vid: params.vid,
    }).then((res) => {
      setEmail(res.data.email);
    });
  }, [params, request]);

  if (loading) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!email) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <PrivateRoute>
      <div className="w-full h-[100vh] lg:w-3/4 mx-auto flex justify-center items-center">
        <div className="border border-solid border-gray-200 flex flex-col items-center p-4 rounded-lg">
          <FaCheck className="text-2xl text-green-500" />
          <span className="my-4">
            <span className="font-bold">{email}</span> has been verified
            successfully!
          </span>
          <Button
            icon={<FaHome size={10} />}
            onClick={() => {
              navigate("/");
            }}
          >
            <span className="text-xs">Home</span>
          </Button>
        </div>
      </div>
    </PrivateRoute>
  );
}
