import {
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { MdOutlinePermMedia } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IService } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  photo: UploadFile[];
  service: IService | null;
};

export default function GalleryNew() {
  useTitle("New Photo");

  const [info, setInfo] = useState<TInfo>({ photo: [], service: null });
  const [services, setServices] = useState<IService[]>([]);

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const { Preview, onPreview } = useImagePreview();

  const [addRequest, adding] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onAdd = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("operation", "A");
    _info.append("title", values.title);
    _info.append("photo", info.photo[0].originFileObj!);
    _info.append("customer_email", values.customer_email || "");
    _info.append("descr", values.descr);

    if (info.service) {
      _info.append("service_id", `${info.service.service_id}`);
    }

    addRequest("post", "photos/api/webaddupdate/", _info).then(() => {
      navigate(-1);
      dispatch(
        setAlert({
          type: "success",
          message: "PHOTO",
          description: "Photo added successfully!",
        })
      );
    });
  };

  useEffect(() => {
    listRequest("post", "services/api/servicesSearch/", {
      email: user?.email,
      pageRequested: 1,
    }).then((res) => {
      setServices(res.data.services_list);
    });
  }, [user, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdOutlinePermMedia className="mr-2" /> Photo
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onAdd}>
          <Form.Item
            label="Photo"
            name="photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo.length === 0)
                    return Promise.reject("Please select a photo!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) => setInfo({ ...info, photo: fileList })}
            >
              {info.photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input photo title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="descr" required>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Service" name="service">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: services.map((s) => ({
                  key: s.service_name,
                  label: s.service_name,
                  onClick: () =>
                    setInfo({
                      ...info,
                      service: s,
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.service ? info.service.service_name : "Select a Service"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Customer" name="customer_email">
            <Input />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={adding}
              htmlType="submit"
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PrivateRoute>
  );
}
