import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { useCTX } from "../state";

export default function Footer() {
  const {
    state: { settings },
  } = useCTX();
  const { t } = useTranslation();
  return (
    <div className="bg-[#28282B] h-[300px] flex flex-col items-center justify-center mt-16">
      <div className="w-2/5 md:w-1/5">
        <Image
          src={`/images/logos/${settings.type}.png`}
          preview={false}
          className="pt-5"
        />
      </div>
      <div className="mb-4">
        <a
          href="https://www.twitter.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter size={20} color="#1DA1F2" />
        </a>
        <a
          href="https://www.facebook.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook size={20} color="#1877F2" />
        </a>
        <a
          href="https://www.linkedin.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin size={20} color="#0A66C2" />
        </a>
        <a
          href="https://www.instagram.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram size={20} color="#fa7e1e" />
        </a>
        <a
          href="https://www.pinterest.com"
          className="mr-2"
          target="_blank"
          rel="noreferrer"
        >
          <FaPinterest size={20} color="#E60023" />
        </a>
      </div>
      <div className="flex pb-1">
        <Typography.Text className="text-gray-300 font-bold flex items-center text-xs">
          <AiFillMail className="mr-2" color={settings.color} />{" "}
          info@pontres.com
        </Typography.Text>
        <span className="font-bold mx-2" style={{ color: settings.color }}>
          |
        </span>
        <Typography.Text className="text-gray-300 font-bold flex items-center text-xs">
          +55 (16)-3014-5963{" "}
          <AiFillPhone className="ml-1" color={settings.color} />
        </Typography.Text>
      </div>
      <Typography.Text className="text-gray-300 font-bold text-xs">
        &copy; {t("footer")}
      </Typography.Text>
    </div>
  );
}
