import {
  Button,
  Dropdown,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineUser } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { FiSave } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useImagePreview } from "../../hooks/useImagePreview";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { ICity, MCalendarMode } from "../../lib/model";
import { getBase64 } from "../../lib/utils";
import { useCTX } from "../../state";
import { setAlert, setProvider } from "../../state/actionCreators";

type TInfo = {
  calendar: string;
  photo: UploadFile[];
  city: ICity;
  service_cities: ICity[];
};

export default function Profile() {
  useTitle("Profile");

  const {
    state: { user, provider },
    dispatch,
  } = useCTX();

  const [cities, setCities] = useState<ICity[]>([]);
  const [info, setInfo] = useState<TInfo>({
    calendar: provider?.calendar || "A",
    photo: provider?.photo
      ? [
          {
            name: provider.photo.split("/").slice(-1)[0],
            uid: provider.photo,
            url: provider.photo,
            thumbUrl: provider.photo,
          },
        ]
      : [],
    city: provider!.city,
    service_cities: provider!.service_cities,
  });

  const navigate = useNavigate();
  const { Preview, onPreview } = useImagePreview();
  const [updateRequest, updating] = useRequest();
  const [listRequest, loading] = useRequest();

  const onSubmit = (values: any) => {
    const _info = new FormData();
    _info.append("email", user!.email);
    _info.append("prov_name", values.profession);
    _info.append("description", values.experience);
    _info.append("calendar_mode", info.calendar);
    _info.append("cityId", `${info.city.id}`);

    if (info.photo.length > 0 && info.photo[0].thumbUrl?.includes("data:")) {
      _info.append("photoUrl", info.photo[0].originFileObj!);
    }

    const oldCities = [...provider!.service_cities];
    const newCities = [...info.service_cities];

    const added: any[] = [];

    for (const city of newCities) {
      const ocidx = oldCities.findIndex((c) => c.id === city.id);

      if (ocidx === -1) {
        added.push({ op: "A", entity: "city", instance: city.id });
      } else {
        oldCities.splice(ocidx, 1);
      }
    }

    const geoChanges = [
      ...added,
      ...oldCities.map((c) => ({ op: "R", entity: "city", instance: c.id })),
    ];
    _info.append("geoChanges", JSON.stringify(geoChanges));

    updateRequest("post", "providers/api/providerProfileUpdate", _info).then(
      () => {
        navigate(-1);
        dispatch(
          setProvider({
            ...provider!,
            profession: values.profession,
            experience: values.experience,
            city: info.city,
            service_cities: info.service_cities,
            calendar: info.calendar,
            photo: info.photo.length > 0 ? info.photo[0].thumbUrl! : "",
          })
        );
        dispatch(
          setAlert({
            type: "success",
            message: "PROFILE",
            description: "Profile updated successfully!",
          })
        );
      }
    );
  };

  useEffect(() => {
    listRequest("post", "cities/api/list/", {
      email: user?.email,
      pageRequested: 1,
    }).then((res) => {
      setCities(res.data.city_list);
    });
  }, [user?.email, listRequest]);

  if (loading) {
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>;
  }

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center w-full pb-4"
        >
          <AiOutlineUser className="mr-2" /> Profile
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          className="w-full"
          initialValues={{
            profession: provider?.profession,
            experience: provider?.experience,
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Photo"
            name="photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo}
              onPreview={onPreview}
              previewFile={getBase64 as any}
              beforeUpload={() => false}
              onChange={({ fileList }) => setInfo({ ...info, photo: fileList })}
            >
              {info.photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Profession"
            name="profession"
            rules={[
              { required: true, message: "Please input your profession!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Experience"
            name="experience"
            rules={[
              { required: true, message: "Please input your experience!" },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.city)
                    return Promise.reject(new Error("Please input your city!"));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: cities.map((c) => ({
                  key: c.id,
                  label: `${c.name} - ${c.state} - ${c.country}`,
                  onClick: () => setInfo({ ...info, city: c }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.city ? (
                  <div className="flex items-center">
                    <span>{info.city.name}</span>
                  </div>
                ) : (
                  "Select your City"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Service Cities"
            name="service_cities"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.service_cities.length === 0)
                    return Promise.reject(
                      new Error("Please add atleast one city!")
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select cities to service..."
              defaultValue={info.service_cities.map((c) => `${c.id}`)}
              onChange={(values) =>
                setInfo({
                  ...info,
                  service_cities: values.map(
                    (cid) => cities.find((c) => c.id === parseInt(cid))!
                  ),
                })
              }
              options={cities.map((c) => ({
                label: `${c.name} - ${c.state} - ${c.country}`,
                value: `${c.id}`,
              }))}
            />
          </Form.Item>
          <Form.Item label="Calendar" name="calendar">
            <Radio.Group
              buttonStyle="solid"
              className="mb-2"
              defaultValue={info.calendar}
              onChange={(e) => setInfo({ ...info, calendar: e.target.value })}
            >
              {MCalendarMode.keys.map((v) => (
                <Radio.Button value={MCalendarMode.getValue(v)}>
                  {v}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<FiSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
