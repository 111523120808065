import {
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineUpload } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { caxios } from "../../../lib/constants";
import { IDispute, IFact } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  dispute: IDispute | null;
  fact_photo: UploadFile[];
  fact_doc: UploadFile[];
};

export default function FactEdit() {
  useTitle("Edit Fact");

  const [loading, setLoading] = useState(true);
  const [fact, setFact] = useState<IFact | null>(null);
  const [disputes, setDisputes] = useState<IDispute[]>([]);
  const [info, setInfo] = useState<TInfo>({
    dispute: null,
    fact_photo: [],
    fact_doc: [],
  });

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useCTX();
  const { Preview, onPreview } = useImagePreview();
  const [updateRequest, updating] = useRequest();

  useEffect(() => {
    Promise.all([
      caxios.post("disputes/api/factDetail/", {
        email: user?.email,
        factId: params.id,
      }),
      caxios.post("disputes/api/disputesList/", {
        email: user?.email,
        pageRequested: 1,
        isProvider: true,
      }),
    ])
      .then((resps) => {
        setDisputes(resps[1].data.disp_list);
        const f = resps[0].data.fact;
        setFact(f);
        setInfo({
          dispute: resps[1].data.disp_list.find(
            (d: any) => d.disputeId === f.disputeId
          ),
          fact_photo: f.photo
            ? [
                {
                  name: f.photo.split("/").slice(-1)[0],
                  uid: f.photo,
                  url: f.photo,
                  thumbUrl: f.photo,
                },
              ]
            : [],
          fact_doc: f.document
            ? [
                {
                  name: f.document.split("/").slice(-1)[0],
                  uid: f.document,
                  url: f.document,
                  thumbUrl: f.document,
                },
              ]
            : [],
        });
      })
      .finally(() => setLoading(false));
  }, [params.id, user?.email]);

  const onSave = (values: any) => {
    const _info = new FormData();
    _info.append("email", user!.email);
    _info.append("factId", `${fact?.factId}`);
    _info.append("disputeId", `${info.dispute?.disputeId}`);
    _info.append("fact_title", values.title);
    _info.append("fact_descr", values.description);

    if (
      info.fact_photo.length > 0 &&
      info.fact_photo[0].thumbUrl?.includes("data:")
    ) {
      _info.append("fact_photo", info.fact_photo[0].originFileObj!);
    }

    if (
      info.fact_doc.length > 0 &&
      info.fact_doc[0].thumbUrl?.includes("data:")
    ) {
      _info.append("fact_doc", info.fact_doc[0].originFileObj!);
    }

    updateRequest("post", "disputes/api/addUpdateFact/", _info).then((res) => {
      navigate(-1);
      dispatch(
        setAlert({
          type: "success",
          message: "FACT",
          description: "Dispute fact updated successfully!",
        })
      );
    });
  };

  useEffect(() => {
    if (!loading && !fact) {
      navigate(-1);
    }
  }, [loading, fact, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!fact) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <Preview />
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Fact
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            title: fact.title,
            description: fact.description,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Dispute"
            name="dispute"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.dispute)
                    return Promise.reject("Please select a dispute!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: disputes.map((d) => ({
                  key: `[${d.disputeId}] ${d.title}`,
                  label: `[${d.disputeId}] ${d.title}`,
                  onClick: () =>
                    setInfo({
                      ...info,
                      dispute: d,
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.dispute
                  ? `[${info.dispute.disputeId}] ${info.dispute.title}`
                  : "Select a Dispute"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input fact title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please input fact description!" },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Photo" name="fact_photo">
            <Upload
              listType="picture-card"
              fileList={info.fact_photo}
              onPreview={onPreview}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, fact_photo: fileList })
              }
            >
              {info.fact_photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label="Document" name="fact_doc">
            <Upload
              fileList={info.fact_doc}
              onPreview={onPreview}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, fact_doc: fileList })
              }
            >
              {info.fact_doc.length > 0 ? null : (
                <Button icon={<AiOutlineUpload />}>Upload</Button>
              )}
            </Upload>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
