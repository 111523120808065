import { Button, Form, Input, Typography } from "antd";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { useCTX } from "../../state";
import { setAlert } from "../../state/actionCreators";

type TInfo = {
  password: string;
  confirmPassword: string;
};

export default function ResetPassword() {
  useTitle("Reset Password");

  const { dispatch } = useCTX();
  const navigate = useNavigate();
  const [request, loading] = useRequest();

  const onReset = (values: TInfo) => {
    const info = {
      password: values.password,
      token: localStorage.getItem("token"),
    };

    request("post", "users/api/webreset/", info).then(() => {
      dispatch(
        setAlert({
          type: "success",
          message: "Reset",
          description: "Password has been reset successfully!",
        })
      );
      navigate("/");
    });
  };

  return (
    <PrivateRoute>
      <div className="w-full lg:w-1/2 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center py-8"
        >
          <AiOutlineLock className="mr-2" /> Reset Password
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onReset}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 8,
                message: "Password must be minimum 8 characters in length.",
              },
            ]}
          >
            <Input.Password autoFocus />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Please input your password again!" },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || getFieldValue("password") === value)
                    return Promise.resolve();
                  return Promise.reject(new Error("Passwords must match!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              type="primary"
              className="text-xs ml-4"
              icon={<AiOutlineMail size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PrivateRoute>
  );
}
