import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { IoLocateOutline } from "react-icons/io5";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IAddress } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function AddressList() {
  useTitle("Address List");

  const [addresses, setAddresses] = useState<IAddress[]>([]);

  const [deleteAddress, setDeleteAddress] = useState<IAddress | null>(null);

  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useCTX();

  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IAddress>["columns"] = useMemo(
    () => [
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
      },
      {
        key: "city",
        title: "City",
        dataIndex: "city",
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, address: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteAddress(address)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() =>
                navigate(`/customer/addresses/${address.addrId}/edit`)
              }
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest("post", "addresses/api/addressRemove/", {
      email: user?.email,
      addressId: deleteAddress?.addrId,
    }).then(() => {
      setAddresses((old) =>
        old.filter((a) => a.addrId !== deleteAddress?.addrId)
      );
      setDeleteAddress(null);
      dispatch(
        setAlert({
          type: "success",
          message: "ADDRESS",
          description: "Address deleted successfully!",
        })
      );
    });
  };

  useEffect(() => {
    listRequest("post", "addresses/api/addressesList/", {
      email: user?.email,
      pageRequested: 1,
    }).then((res) => {
      setAddresses(res.data.addr_list);
    });
  }, [user, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteAddress}
        title="Delete Address"
        onCancel={() => setDeleteAddress(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete address '{deleteAddress?.name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <IoLocateOutline className="mr-2" /> Addresses
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/customer/addresses/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={addresses} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
