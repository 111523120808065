import { Button, Modal, Spin, Table, TableProps, Tag, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { AiFillBank } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IAccount } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function AccountList() {
  useTitle("Account List");

  const [accounts, setAccounts] = useState<IAccount[]>([]);

  const [deleteAccount, setDeleteAccount] = useState<IAccount | null>(null);

  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useCTX();
  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IAccount>["columns"] = useMemo(
    () => [
      {
        key: "account_name",
        title: "Name",
        dataIndex: "account_name",
      },
      {
        key: "bank",
        title: "Bank",
        dataIndex: "bank",
        render: (_: any, a: any) => <span>{a.bank.bank_name}</span>,
      },
      {
        key: "primary",
        title: "Primary",
        dataIndex: "primary",
        render: (_, a: any) =>
          a.primary ? (
            <Tag color="green" style={{ fontSize: "0.5rem" }}>
              <FaCheck />
            </Tag>
          ) : null,
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, a: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteAccount(a)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/provider/accounts/${a.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest("post", "certificates/api/webdelete/", {
      token: localStorage.getItem("token"),
      cert_id: deleteAccount?.id,
    }).then(() => {
      setAccounts((old) => old.filter((a) => a.id !== deleteAccount?.id));
      setDeleteAccount(null);
      dispatch(
        setAlert({
          type: "success",
          message: "ACCOUNT",
          description: "Account deleted successfully!",
        })
      );
    });
  };

  useEffect(() => {
    listRequest("post", "bankaccounts/api/list/", {
      email: user?.email,
      pageRequested: 1,
    }).then((res) => {
      setAccounts(res.data.bacc_list);
    });
  }, [user?.email, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteAccount}
        title="Delete Account"
        onCancel={() => setDeleteAccount(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete account '
          {deleteAccount?.account_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <AiFillBank className="mr-2" /> Bank Accounts
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/provider/accounts/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={accounts} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
