import { Typography } from "antd";
import { FaFacebook } from "react-icons/fa";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";

export default function SocialNetworks() {
  return (
    <PrivateRoute>
      <div className="w-full lg:w-1/2 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center py-8"
        >
          <FaFacebook className="mr-2" /> Social Networks
        </Typography.Title>
      </div>
    </PrivateRoute>
  );
}
