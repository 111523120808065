import { Avatar, Carousel, Typography } from "antd";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { AiOutlineUser } from "react-icons/ai";
import { useTitle } from "../hooks/useTitle";

function FeedbackCard() {
  return (
    <div className="flex items-center">
      <div className="flex flex-col items-center p-4">
        <Avatar
          size={{ xs: 100, sm: 120, md: 120, lg: 120, xl: 150, xxl: 150 }}
          icon={<AiOutlineUser className="text-gray-700" />}
          className="bg-white outline outline-1 outline-gray-300"
        />
        <span className="font-poppins mt-4 font-bold text-xs sm:text-sm">
          Atticus Caesar
        </span>
      </div>
      <div className="p-4 bg-white">
        <span className="mr-4 font-bold text-2xl">""</span>
        <Typography.Text className="font-poppins">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur,
          architecto beatae ex molestias rerum vel minima debitis nobis officia
          iusto! Ducimus, minima nesciunt consequuntur sed quaerat unde nihil
          libero aspernatur doloremque distinctio reprehenderit quia explicabo
          ipsam sapiente ex illum dicta?
        </Typography.Text>
      </div>
    </div>
  );
}

export default function Home() {
  useTitle("Home");

  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex">
        <div className="w-full md:w-3/5">
          <Typography>
            <Typography.Title className="font-poppins">
              <span className="font-normal">{t("title1")}</span>
              <br />
              <span className="font-normal">{t("title2")}</span>
            </Typography.Title>
            <Typography.Paragraph>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
              illum id eos aspernatur vero explicabo ad nemo delectus fugiat,
              alias mollitia, velit rem libero eveniet animi modi provident? In,
              ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatibus itaque magnam ab placeat nam omnis deleniti commodi
              iure iusto eos.
            </Typography.Paragraph>
            <div className="flex">
              <img
                src="/images/badges/appStore.svg"
                alt="App Store"
                className="w-2/5 md:1/4 cursor-pointer transition-all duration-1000 hover:opacity-70"
              />
              <img
                src="/images/badges/playStore.svg"
                alt="Play Store"
                className="w-2/5 md:1/4 cursor-pointer transition-all duration-1000 hover:opacity-70"
              />
            </div>
          </Typography>
        </div>
        <motion.img
          src="/images/phone.png"
          alt="Phone"
          className="hidden md:block w-52 mx-auto z-10"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
        />
      </div>
      <Carousel
        autoplay
        autoplaySpeed={5000}
        dots={false}
        className="mt-8 md:w-4/5 bg-gray-200 p-4"
      >
        {Array.from(Array(5).keys()).map((k) => (
          <FeedbackCard key={k} />
        ))}
      </Carousel>
    </div>
  );
}
